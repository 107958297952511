<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return{}
  },
};
</script>

<style>
html,
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
fieldset,
legend,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
   height: 100%;
}
a {
  text-decoration: none !important;
  cursor: pointer;
}
img {
  display: block;
}

input {
  border: none;
  outline: none;
}

ul,
ol {
  list-style: none;
}
#app {
  width: 100%;
  min-width: 1230px;
  height: 100%;
  /* background: #f0f0f0; */
  overflow: auto;
  position: relative;
  max-width: 1920px;
  margin: auto;
}
@media only screen and (max-width: 1320px) and (min-width: 0px) {
  header,
  .legend,
  .typeArea,
  .twelBox,
  /* .head, */
  .numberBox,
  .last {
    width: 95% !important;
  }
}
.el-dialog__wrapper{
  overflow: hidden;
}
</style>
