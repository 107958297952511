// function setRem() { // 基准大小 
//   const baseSize = 16; 
//   let vW = window.innerWidth<=1440?1440:window.innerWidth; // 当前窗口的宽度 
//   let basePc = baseSize / 1920; 
//   let rem = vW * basePc; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应font-size值 
//   document.documentElement.style.fontSize = rem + 'px';
// }// 初始化
// setRem();// 改变窗口大小时重新设置rem 
// window.addEventListener('resize', () => { setRem(); });
const baseSize = 16
function setRem () {
  const width =  document.documentElement.clientWidth>1920?1920:document.documentElement.clientWidth
  const scale = width / 1920
  let fontSize = (baseSize * Math.min(scale, 2))>12 ? (baseSize * Math.min(scale, 2)): 12
  document.documentElement.style.fontSize = fontSize + 'px'
}
setRem()
window.onresize = function () {
  setRem()
}